/**
 * Home
 *
 * 01. Spotlight
 * 02. Course
 * 03. Therapy
 * 04. Pathology
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    window.addEventListener("load", function() {
        'use strict';

        setTimeout(function () {

            /**
             * Spotlight
             *
             * @requires plugins/swiper.js
             *
             * @see https://codepen.io/aaronkahlhamer/pen/GveaXP
             */
            new Swiper('.js-spotlightCarousel', {
                spaceBetween: 0,
                speed: 1600,
                loop: true,
                effect: 'fade',
                autoHeight: true,
                slidesPerView: 1,
                grabCursor: false,
                mousewheel: false,
                lazy: true,
                preventClicks: false,
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: '.js-spotlightCarouselPagination',
                    type: 'fraction',
                },
                breakpointsInverse: true,
                breakpoints: {
                    // >= 1200px
                    1200: {
                        autoHeight: false
                    }
                },
            });


            /**
             * Pathology
             */
            (function () {
                var images = $('.js-pathologyImages img');

                $('.js-pathologyLinks').on('mouseover', 'li', function (event) {
                    var index = $(this).index();

                    images.removeClass('is-active');
                    images.eq(index).addClass('is-active');
                });
            }());


            /**
             * Course
             *
             * @requires plugins/swiper.js
             *
             * @see https://codepen.io/aaronkahlhamer/pen/GveaXP
             */
            (function () {
                // breakpoint where swiper will be destroyed
                // and switches to a dual-column layout
                var breakpoint = window.matchMedia('(max-width: 1199px)');

                // keep track of swiper instances to destroy later
                var courseCarousel = void 0;

                var breakpointChecker = function breakpointChecker() {
                    // if larger viewport and multi-row layout needed
                    if (breakpoint.matches === true) {
                        // clean up old instances and inline styles when available
                        if (courseCarousel !== undefined) courseCarousel.destroy(true, true);
                        // or/and do nothing
                        return;
                        // else if a small viewport and single column layout needed
                    } else if (breakpoint.matches === false) {
                        // fire small viewport version of swiper
                        return enableSwiper();
                    }
                };

                // title
                var titles = [];

                $('.js-courseCarousel .o-course').each(function() {
                    titles.push($(this).find('a').data('title'))
                });

                var enableSwiper = function enableSwiper() {
                    new Swiper('.js-courseCarousel', {
                        speed: 1600,
                        spaceBetween: 0,
                        loop: true,
                        effect: 'fade',
                        slidesPerView: 1,
                        grabCursor: false,
                        pagination: {
                            el: '.js-courseCarouselPagination',
                            clickable: true,
                            renderBullet: function (index, className) {
                                return '<span class="' + className + '">' + titles[index] + '</span>';
                            },
                        }
                    });
                };

                // keep an eye on viewport size changes
                breakpoint.addListener(breakpointChecker);

                // kickstart
                breakpointChecker();
            }());



            /**
             * Posts
             *
             * Imposto il setTimeout perchè altrimenti la slide sbrocca nel caso del css critico.
             *
             * @requires plugins/swiper.js
             *
             * @see https://codepen.io/aaronkahlhamer/pen/GveaXP
             */
            new Swiper('.js-postCarousel', {
                slidesPerView: 'auto',
                grabCursor: true,
                watchOverflow: true,
                navigation: {
                    prevEl: '.js-postCarouselPrev',
                    nextEl: '.js-postCarouselNext'
                }
            });

        }, 1000);

    });

}(jQuery));

