/**
 * Global
 *
 * 01. SVG
 * 02. Object-Fit Image
 * 03. Social Share
 * 04. Video Replacement
 * 05. Fluid Video
 * 06. Search
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
    'use strict';

    /**
     * SVG
     *
     * @see {@link libraries/svg4everybody.js}
     */
    svg4everybody();


    /**
     * Object-Fit Image
     *
     * @see {@link libraries/ofi.js}
     */
    objectFitImages();


    /**
     * Video Replacement
     *
     * @global jQuery
     * @author Daniele De Nobili
     */
    (function () {
        $('.js-video').each(function () {
            var self = $(this),
                image = self.find('.js-videoimage'),
                video = self.find('.js-videoiframe');

            image.on('click', function () {
                var url = '';

                image.hide();

                if (video.data('source') === 'vimeo') {
                    url = 'https://player.vimeo.com/video/' + video.data('video-id') + '?autoplay=1&byline=0&badge=0&title=0&loop=1';
                } else {
                    url = 'https://www.youtube.com/embed/' + video.data('video-id') + '?autoplay=1&amp;rel=0&amp;showinfo=0&amp;&amp;autohide=1&amp;color=white';
                }

                var iframe = $('<iframe width="1200" height="675" src="' + url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

                iframe.attr('data-title', video.data('title'));

                video
                    .html(iframe)
                    .show();
            });
        });
    }());


    /**
     * Fluid Video
     *
     * @requires plugins/jquery.fitvids.js
     */
    $('.single__body iframe[data-src*="youtube"], .single__body iframe[data-src*="vimeo"]').parent().fitVids();


    /**
     * Back To Top
     *
     * @see https://github.com/CodyHouse/back-to-top/
     */
    (function () {
        // Back to Top - by CodyHouse.co
        var backTop = document.getElementsByClassName('js-backtotop')[0],
            // browser window scroll (in pixels) after which the "back to top" link is shown
            offset = 300,
            //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
            offsetOpacity = 1200,
            scrollDuration = 700,
            scrolling = false;
        if (backTop) {
            //update back to top visibility on scrolling
            window.addEventListener("scroll", function (event) {
                if (!scrolling) {
                    scrolling = true;
                    (!window.requestAnimationFrame) ? setTimeout(checkBackToTop, 250) : window.requestAnimationFrame(checkBackToTop);
                }
            });
        }

        function checkBackToTop() {
            var windowTop = window.scrollY || document.documentElement.scrollTop;
            (windowTop > offset) ? addClass(backTop, 'c-backtotop--show') : removeClass(backTop, 'c-backtotop--show', 'c-backtotop--fade-out');
            (windowTop > offsetOpacity) && addClass(backTop, 'c-backtotop--fade-out');
            scrolling = false;
        }

        //class manipulations - needed if classList is not supported
        function hasClass(el, className) {
            if (el.classList) return el.classList.contains(className);
            else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
        }

        function addClass(el, className) {
            var classList = className.split(' ');
            if (el.classList) el.classList.add(classList[0]);
            else if (!hasClass(el, classList[0])) el.className += " " + classList[0];
            if (classList.length > 1) addClass(el, classList.slice(1).join(' '));
        }

        function removeClass(el, className) {
            var classList = className.split(' ');
            if (el.classList) el.classList.remove(classList[0]);
            else if (hasClass(el, classList[0])) {
                var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
                el.className = el.className.replace(reg, ' ');
            }
            if (classList.length > 1) removeClass(el, classList.slice(1).join(' '));
        }
    })();


    /**
     * Testimonials
     */
    window.addEventListener("load", function () {
        'use strict';

        setTimeout(function () {
            new Swiper('.js-testimonialsCarousel', {
                slidesPerView: 'auto',
                grabCursor: true,
                watchOverflow: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true,
                },
                pagination: {
                    el: '.js-testimonialsCarouselPag',
                    clickable: true
                },
            });
        }, 1000);
    });

}(jQuery));
