/**
 * Singular
 *
 * 01. Facebook SDK
 * 02. Sticky Social Bar
 * 03. Responsive Table
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Facebook SDK
     */
    if (typeof fbLang !== 'undefined') {
        var lazyFb = document.querySelectorAll('.js-lazy-fb');

        window.onscroll = function () {
            if (!window.fbAsyncInit) {
                var i, rect;

                for (i = 0; i < lazyFb.length; i++) {
                    rect = lazyFb[i].getBoundingClientRect();
                    if (rect.top < window.innerHeight) {
                        window.fbAsyncInit = function () {
                            FB.init({
                                appId: fbAppId,
                                xfbml: 1,
                                version: 'v2.12'
                            });
                        };
                        loadScript('//connect.facebook.net/' + fbLang + '/sdk.js');
                        window.onscroll = null;
                    }
                }
            }
        };
    }


    /**
     * Sticky Social Bar
     */
    var shareSticky = $(".js-share");

    $(window).scroll(function() {
        var topPos = $(this).scrollTop();

        shareSticky.toggleClass('is-show', topPos >= 200);
    });


    /**
     * Responsive Table
     */
    $('.single__content table').each(function () {
        var table = $(this),
            th = table.find('thead th'),
            headers = [];

        th.each(function () {
            headers.push($(this).text());
        });

        table.find('tbody tr').each(function () {
            var cells = $(this).find('td');

            headers.map(function (value, index) {
                cells.eq(index).attr('data-label', value);
            });
        });
    });

}(jQuery));
