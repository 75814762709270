/**
 * Menu
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
    'use strict';

    //open/close mega-navigation
    $('.nav__trigger').on('click', function (event) {
        event.preventDefault();
        toggleNav();
    });

    //close meganavigation
    $('.nav__dropdown .nav__close').on('click', function (event) {
        event.preventDefault();
        toggleNav();
    });

    //on mobile - open submenu
    $('.menu__item--parent').children('a').on('click', function (event) {
        //prevent default clicking on direct children of .menu__item--parent
        event.preventDefault();
        var selected = $(this);
        selected.next('ul').removeClass('is-hidden').end().parent('.menu__item--parent').parent('ul').addClass('move-out');
    });

    //on desktop - differentiate between a user trying to hover over a dropdown item vs trying to navigate into a submenu's contents
    var submenuDirection = (!$('.nav').hasClass('open-to-left')) ? 'right' : 'left';
    $('.menu').menuAim({
        activate: function (row) {
            $(row).children().addClass('is-active').removeClass('fade-out');
            if ($('.menu .fade-in').length == 0) $(row).children('ul').addClass('fade-in');
        },
        deactivate: function (row) {
            $(row).children().removeClass('is-active');
            if ($('li.menu__item--parent:hover').length == 0 || $('li.menu__item--parent:hover').is($(row))) {
                $('.menu').find('.fade-in').removeClass('fade-in');
                $(row).children('ul').addClass('fade-out')
            }
        },
        exitMenu: function () {
            $('.menu').find('.is-active').removeClass('is-active');
            return true;
        },
        submenuDirection: submenuDirection,
    });

    //submenu items - go back link
    $('.go-back').on('click', function () {
        var selected = $(this),
            visibleNav = $(this).parent('ul').parent('.menu__item--parent').parent('ul');
        selected.parent('ul').addClass('is-hidden').parent('.menu__item--parent').parent('ul').removeClass('move-out');
    });

    function toggleNav() {
        var navIsVisible = (!$('.nav__dropdown').hasClass('dropdown-is-active')) ? true : false;
        $('.nav__dropdown').toggleClass('dropdown-is-active', navIsVisible);
        $('.nav__trigger').toggleClass('dropdown-is-active', navIsVisible);
        if (!navIsVisible) {
            $('.nav__dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                $('.menu__item--parent ul').addClass('is-hidden');
                $('.move-out').removeClass('move-out');
                $('.is-active').removeClass('is-active');
            });
        }
    }

}(jQuery));
